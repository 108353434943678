import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonldserviceService {

  static scriptType = 'application/ld+json';

constructor(
  @Inject(DOCUMENT) private _document: Document,
  @Inject(PLATFORM_ID) private platformId: Object
) { }


   static pageSchema = (name : string, url :string, description: string) => 
     { 
       return {
         '@context'  :
         'https://schema.org',
       '@type'     :
         'WebPage',
       url         : url,
       name        : name,
       description : description,
       'publisher' :
       {
         '@type'     :'Organization',
         'logo'      : {
           '@type'   : 'ImageObject',
           'url'     : 'https://services.ifa.com/art/images/1614_2019-5-7-11-52-54.jpg'
         },
         url         :'https://www.ifa.com',
         name        :'Index Fund Advisors',
         foundingDate:"1999-03-01T00:00:00",
         image:"https://services.ifa.com/art/images/399_2013-10-1-16-47-5.jpg",
         founder:"Mark T. Hebner",
         description:"IFA is a Registered Investment Adviser with the U.S. Securities and Exchange Commission that provides investment advice to individuals, trusts, corporations, non-profits, and public and private institutions.",
       }
       }
     };

   static articleSchema = (name : string, url :string, id : string, publishDate: string, modifiedDate: string, author: string, keywords: string, description: string, text: string, imageUrl: string, phoneLocal: string, faxNumber: string, mailingAddress: string, views:number) => {
     return {
       '@context'  :
         'https://schema.org',
       '@type'     :
         'Article',
       url         : url,
       name        : name,
       headline    : name, 
       identifier  : id,
       datePublished: publishDate,
       dateModified: modifiedDate,
       author      : author,
       keywords    : keywords,
       description : description,
       articleBody : text,
       thumbnailUrl: imageUrl,
       image       : imageUrl,
       'publisher' :
       {
         '@type'     :'Organization',
         'logo'      : {
           '@type'   : 'ImageObject',
           'url'     : 'https://services.ifa.com/art/images/1614_2019-5-7-11-52-54.jpg'
         },
         url         :'https://www.ifa.com',
         name        :'Index Fund Advisors',
         foundingDate:"1999-03-01T00:00:00",
         address     : mailingAddress,
         telephone   :phoneLocal,
         faxNumber   :faxNumber,
         image:"https://services.ifa.com/art/images/399_2013-10-1-16-47-5.jpg",
         founder:"Mark T. Hebner",
         description:"IFA is a Registered Investment Adviser with the U.S. Securities and Exchange Commission that provides investment advice to individuals, trusts, corporations, non-profits, and public and private institutions.",
       },
       'interactionStatistic':[{
           '@type':    'InteractionCounter',
           'interactionType': 'https:///schema.org/watchAction',
           'userInteractionCount': views
       }]
     };
   };

   //SEE: https://developers.google.com/search/docs/appearance/structured-data/profile-page
   static advisorSchema = (name : string, url :string, description: string, image:string, date:string, jobTitle:string) => 
     { 
       return {
        '@context': 'https://schema.org',
        '@type': 'ProfilePage',
        'dateCreated': date,
        'dateModified': date,
        'mainEntity': { 
          '@type'       :'Person',
          name          : name,
          identifier    : url,
          url           : url,
          description   : description, //plainText
          image         : image,
          dateModified  : date,
          jobTitle      : jobTitle,
          worksFor   :
          {
              '@type'     :'Organization',
              'logo'      : {
                '@type'   : 'ImageObject',
                'url'     : 'https://services.ifa.com/art/images/1614_2019-5-7-11-52-54.jpg'
              },
              url         :'https://www.ifa.com',
              name        :'Index Fund Advisors',
              foundingDate:"1999-03-01T00:00:00",
              image:"https://services.ifa.com/art/images/399_2013-10-1-16-47-5.jpg",
              founder:"Mark T. Hebner",
              description:"IFA is a Registered Investment Adviser with the U.S. Securities and Exchange Commission that provides investment advice to individuals, trusts, corporations, non-profits, and public and private institutions.",
            }
          }
      }
     };

     static videoSchema = (name : string, url :string, id : string, publishDate: string, modifiedDate: string, author: string, keywords: string, description: string, text: string, imageUrl: string, phoneLocal: string, faxNumber: string, mailingAddress: string, views:number, video:string) => {
      return {
        '@context'  :
          'https://schema.org',
        '@type'     :
          'VideoObject',
        url         : url,
        name        : name, 
        datePublished: publishDate, 
        uploadDate: publishDate,       
        author      : author,       
        description : description,
        embedUrl : video,
        thumbnailUrl: imageUrl,
        thumbnail   : imageUrl,
        'publisher' :
        {
          '@type'     :'Organization',
          'logo'      : {
            '@type'   : 'ImageObject',
            'url'     : 'https://services.ifa.com/art/images/1614_2019-5-7-11-52-54.jpg'
          },
          url         :'https://www.ifa.com',
          name        :'Index Fund Advisors',
          foundingDate:"1999-03-01T00:00:00",
          address     : {
            "@type": "PostalAddress",
            "streetAddress": "19200 Von Karman Ave. Suite 150",
            "addressLocality": "Irvine",
            "addressRegion": "California",
            "postalCode":"92612",
            "addressCountry":"US"
          },
          telephone   :phoneLocal,
          faxNumber   :faxNumber,
          image:"https://services.ifa.com/art/images/399_2013-10-1-16-47-5.jpg",
          founder:"Mark T. Hebner",
          description:"IFA is a Registered Investment Adviser with the U.S. Securities and Exchange Commission that provides investment advice to individuals, trusts, corporations, non-profits, and public and private institutions.",
        },
        'interactionStatistic':[{
            '@type':    'InteractionCounter',
            'interactionType': 'https:///schema.org/watchAction',
            'userInteractionCount': views
        }]
      };
    };

   
   
   getAdvisorSchema(name : string, url :string, description: string, image:string, date:string, jobTitle:string): Record<string, any> {
     return JsonldserviceService.advisorSchema(name, url, description, image, date, jobTitle);
   }
   getArticleSchema(name : string, url :string, id : string, publishDate: string, modifiedDate: string, author: string, keywords: string, description: string, text: string, imageUrl: string, phoneLocal: string, faxNumber: string, mailingAddress: string, views:number): Record<string, any> {
     return JsonldserviceService.articleSchema(name , url, id , publishDate, modifiedDate, author, keywords, description, text, imageUrl, phoneLocal, faxNumber, mailingAddress, views);
   }   
   getPageSchema(name : string, url :string, description: string): Record<string, any> {
    return JsonldserviceService.pageSchema(name, url, description);
  }
   getVideoSchema(name : string, url :string, id : string, publishDate: string, modifiedDate: string, author: string, keywords: string, description: string, text: string, imageUrl: string, phoneLocal: string, faxNumber: string, mailingAddress: string, views:number, video:string): Record<string, any> {
    return JsonldserviceService.videoSchema(name , url, id , publishDate, modifiedDate, author, keywords, description, text, imageUrl, phoneLocal, faxNumber, mailingAddress, views, video);
  }

   removeStructuredData(): void {
      //if (isPlatformBrowser(this.platformId)) {
       //Safe to use Document (SSR)   
       const els: Element[] = [];
       [ 'structured-data', 'structured-data-org' ].forEach(c => {
         els.push(...Array.from(this._document.head.getElementsByClassName(c)));
       });
       els.forEach(el => this._document.head.removeChild(el));
     //}
	 }

   insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
     //if (isPlatformBrowser(this.platformId)) {
       //Safe to use Document (SSR)   
       let script;
       let shouldAppend = false;
       if (this._document.head.getElementsByClassName(className).length) {
         script = this._document.head.getElementsByClassName(className)[0];
       } else {
         script = this._document.createElement('script');
         shouldAppend = true;
       }
       script.setAttribute('class', className);
       script.setAttribute('type', JsonldserviceService.scriptType);


       if(!shouldAppend)
       {
         script.appendChild(this._document.createTextNode(","));
       }
       script.appendChild(this._document.createTextNode(JSON.stringify(schema)));
       if (shouldAppend) {
         this._document.head.appendChild(script);
       }
     //}
   }

  // getPageSchema(a:any, b:any, c:any){}
  // removeStructuredData(){}
  // insertSchema(s:any){}

}
